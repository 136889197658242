import { axiosAccountInstance } from "api/Request";

import { formatDate } from "helpers/getDate";
import history from "helpers/History";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "../../../../components/Toastify";
// import { ReactSearchAutocomplete } from "react-search-autocomplete";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
} from "reactstrap";
import { setEmployeeListInfo } from "redux/actions/super-admin/EmployeeListAction";
import { authRequest } from "api/Request";
import Tables from "views/examples/Tables";
import EmployeeModal from "components/Modals/EmployeeModal";
import { fetchUserData } from "helpers/storageData";
import viewIcn from "../../../../assets/img/icons/customIcons/view.svg";

function EmployeeSearchCard({
  searchFormData,
  tab,
  loader,
  isEmployeeUnavailable,
}) {
  const dispatch = useDispatch();
  const companyListing = () => {
    const response = authRequest({ url: "/companylist" });
    response.then(
      function (result) {
        // setCompanyListData(result.body.data);
      },
      (err) => {
        // toast.error("Error Occureed"); // Error: "Promise rejected"
        console.log(err);
      }
    );
  };
  useEffect(() => {
    companyListing();
  }, []);
  // const [companyName, setCompanyName] = useState("");
  // const [companyListData, setCompanyListData] = useState([]);
  const [, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [empModal, setEmpModal] = useState(false);
  const [errors, setErrors] = useState(null);
  const [currentCurrencyCode, setCurrentCurrencyCode] = useState(null);
  const [employeeData, setEmployeeData] = useState({});
  const [currentBooking, setCurrentBooking] = useState([]);
  const [bookingFormData, setBookingFormData] = useState({
    employeeId: "",
    durationFromDate: "",
    durationtoDate: "",
    hourlyPayment: "",
    currencyCode: "",
    remarks: "",
  });
  const [loading, setLoading] = useState(false);

  const socketInstance = useSelector((state) => state.socketReducer.result);

  // const fetchCurrentBooking = useSelector(
  //   (state) => state.CurrentBookingListReducer
  // );

  // console.log("fetchCurrentBooking",fetchCurrentBooking)
  const fetchBookingEmployeeListResult = useSelector(
    (state) => state.EmployeeListReducer.result
  );
  // console.log("fetchBookingEmployeeListResult",fetchBookingEmployeeListResult)

  useEffect(() => {
    if (fetchBookingEmployeeListResult) {
      setData(fetchBookingEmployeeListResult.employees);
    }
    return () => {
      setData([]);
    };
  }, [fetchBookingEmployeeListResult]);

  const handleOnChange = (e) => {
    setBookingFormData({ ...bookingFormData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: null });
  };

  const bookingHandler = (item) => {
    setBookingFormData({
      ...bookingFormData,
      hourlyPayment: item?.hourlyPayment,
      currencyCode: item?.currencyCode,
      employeeId: item?.employeeId,
      companyName: item?.companyName,
      empId: item?.empId,
    });
    setCurrentCurrencyCode(item?.currencyCode);

    setModal(!modal);
    return;
  };
  const close = () => {
    setBookingFormData({
      employeeId: "",
      empId: "",
      durationFromDate: "",
      durationtoDate: "",
      hourlyPayment: "",
      currencyCode: "",
      companyName: "",
      remarks: "",
    });
    setCurrentBooking([]);
    setEmployeeData({});
    setEmpModal(false);
    setModal(false);
    setErrors("")
  };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const validation = () => {
    const {
      // employeeId,
      durationFromDate,
      durationtoDate,
      hourlyPayment,
      currencyCode,
      remarks,
    } = bookingFormData;

    const errorsFound = {};
    // if (!employeeId) errorsFound["employeeId"] = "Oops! please add employeeId";
    if(durationFromDate>durationtoDate && durationFromDate && durationtoDate)
    errorsFound["durationFromDate"] = "Starting date must be less than closing date";
    if (!durationFromDate)
      errorsFound["durationFromDate"] = "Starting date is required";
    if (!durationtoDate)
      errorsFound["durationtoDate"] = "Closing date is required";
    if (!hourlyPayment)
      errorsFound["hourlyPayment"] = "Hourly payment is required";
    if (!currencyCode)
      errorsFound["currencyCode"] = "Currency code is required";
    if (!remarks) errorsFound["remarks"] = "Remarks is required";

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    const isValid = await validation();

    if (isValid) {
      const finalFormData = bookingFormData;
      axiosAccountInstance
        .post("/createbooking", finalFormData)
        .then((res) => {
          toast.success(res.data.body.message);
          history.push("/admin/booking-details");
          setBookingFormData({
            employeeId: "",
            empId: "",
            durationFromDate: "",
            durationtoDate: "",
            hourlyPayment: "",
            currencyCode: "",
            companyName: "",
            remarks: "",
          });
          const finalFormData = {
            ...searchFormData,
            fromDate: formatDate(),
            toDate: formatDate(1),
            page: tab,
          };

          socketInstance.emit("message", {
            type: "CREATE_BOOKING",
            subType: "UPDATE_PENDING_BOOKING_APPROVAL_REQUESTS",
            key: bookingFormData?.employeeId,
            extraInfo: fetchUserData()?.companyName,
          });
          dispatch(setEmployeeListInfo("booking", finalFormData));
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
      setBookingFormData("");
      setModal(false);
    } else {
      // your code here
    }
  };

  const viewHandler = async (dataObj) => {
    setLoading(true);
    setEmployeeData(dataObj);
    setEmpModal(true);

    await axiosAccountInstance
      .get(`/employee_current_bookings?employeeId=${dataObj?.employeeId}`)
      .then((res) => {
        const currentData = res.data.body.data.bookingHistory;
        setCurrentBooking(currentData);
      });

    setLoading(false);
  };

  const colums = [
    { columnName: "Company", columnTag: "companyName", type: "data" },

    { columnName: "Designation", columnTag: "designation", type: "data" },
    {
      columnName: "Experience",
      columnTag: "experience",
      type: "text",
    },
    { columnName: "Skills", columnTag: "skillSets", type: "mapping" },

    {
      columnName: "Hourly Rate",
      columnTag: "hourlyPayment",
      type: "paymentMapping",
    },
    // { columnName: "Currency", columnTag: "currencyCode", type: "data" },
    {
      columnName: "Book Employee",
      columnTag: "BookEmployee",
      type: "",
      actionBookEmployee: bookingHandler,
    },

    // { columnName: "", columnTag: "", type: "" },
  ];

  const actionButtons = [
    {
      columnName: "",
      btnTitle: "View",
      btnIcon: viewIcn,
      btnTooltip: "View",
      type: "actionButton",
      btn: "ViewEmployee",
      btnType: "primary",
      actionFunction: viewHandler,
    },
    // {
    //   columnName: "",
    //   btnTitle: "Book Employee",
    //   btnIcon: bookempIcn,
    //   btnTooltip: "Book Employee",
    //   type: "actionButton",
    //   btn: "BookEmployee",
    //   btnType: "primary",
    //   actionFunction: bookingHandler,
    // },
  ];

  return (
    <div>
      <Tables
        coloums={colums}
        data={fetchBookingEmployeeListResult?.employees}
        actionButtons={actionButtons}
        loader={loader}
        isBookEmployee={true}
        isEmployeeUnavailable={isEmployeeUnavailable}
      />

      <Modal toggle={close} centered isOpen={modal}>
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder">
              {" "}
              Book employee
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase">
                <Button onClick={close} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <p className="font-weight-normal">
            Please select start date and end date of your booking
          </p>
          <FormGroup row>
            <Col sm={6}>
              <Label for="durationFromDate">
                Starting Date
                 <span style={{ color: "red" }}>*</span>
                </Label>
              <Input
                className="border"
                id="durationFromDate"
                name="durationFromDate"
                type="date"
                value={bookingFormData.durationFromDate}
                onChange={(e) => handleOnChange(e)}
                min={disablePastDate()}
                invalid={!!errors && !!errors.durationFromDate}
              />
              <FormFeedback
                className={errors?.durationFromDate && "invalid-field"}
              >
                {errors?.durationFromDate}
              </FormFeedback>
            </Col>
            <Col sm={6}>
              <Label for="durationtoDate">
                Closing Date
               <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                className="border"
                id="durationtoDate"
                name="durationtoDate"
                type="date"
                value={bookingFormData.durationtoDate}
                onChange={(e) => handleOnChange(e)}
                min={disablePastDate()}
                invalid={!!errors && !!errors.durationtoDate}
              />
              <FormFeedback
                className={errors?.durationtoDate && "invalid-field"}
              >
                {errors?.durationtoDate}
              </FormFeedback>
            </Col>
          </FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  Employee ID
                </Label>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ backgroundColor: "#e9ecef" }}>
                      <i className="ni ni-single-02" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input name="empId" value={bookingFormData?.empId} readOnly />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  Company
                </Label>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ backgroundColor: "#e9ecef" }}>
                      <i className="ni ni-building" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="companyName"
                    value={bookingFormData.companyName}
                    readOnly
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  Currency
                  {/* <span style={{ color: "red" }}>*</span> */}
                </Label>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ backgroundColor: "#e9ecef" }}>
                      <i className="fa fa-credit-card" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="currency"
                    type="text"
                    name="currencyCode"
                    value={currentCurrencyCode}
                    onChange={(e) => handleOnChange(e)}
                    invalid={!!errors && !!errors.currencyCode}
                    disabled
                  />

                  {/* <option value={item.currencyCode} disabled selected>
                          {item.currencyCode}
                        </option> */}
                  {/* </Input> */}
                  <FormFeedback
                    className={errors?.currencyCode && "invalid-field"}
                  >
                    {errors?.currencyCode}
                  </FormFeedback>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  Payment
                  {/* <span style={{ color: "red" }}>*</span> */}
                </Label>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ backgroundColor: "#e9ecef" }}>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="Hourly Payment"
                    // type="text"
                    name="hourlyPayment"
                    value={bookingFormData.hourlyPayment}
                    readOnly
                    // onChange={(e) => handleOnChange(e)}
                    invalid={!!errors && !!errors.hourlyPayment}
                  />
                </InputGroup>
                <FormFeedback
                  className={errors?.hourlyPayment && "invalid-field"}
                >
                  {errors?.hourlyPayment}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup className="mb-0">
            <Label
              style={{ fontSize: "10px" }}
              for="remarks"
              className="text-uppercase"
            >
              Remarks<span style={{ color: "red" }}>*</span>
            </Label>
            <InputGroup className="input-group-alternative shadow-none border">
              <Input
                placeholder="Remarks"
                type="textarea"
                name="remarks"
                onChange={(e) => handleOnChange(e)}
                value={bookingFormData.remarks}
                invalid={!!errors && !!errors.remarks}
              />
            </InputGroup>
            <FormFeedback className={errors?.remarks && "invalid-field"}>
              {errors?.remarks}
            </FormFeedback>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={onSubmit} color="primary">
            Submit
          </Button>
        </ModalFooter>
      </Modal>
      <EmployeeModal
        modal={empModal}
        close={close}
        data={employeeData}
        currentData={currentBooking}
        loading={loading}
      />
    </div>
  );
}

export default EmployeeSearchCard;
