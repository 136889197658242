// reactstrap components
import Toggle from "components/switch/Toggle";
import { Card, Table, Button } from "reactstrap";
import moment from "moment";
import updownimg from "../../assets/img/icons/customIcons/up-down.svg";
import disabledDoc from "../../assets/img/icons/customIcons/docInverted.svg";
import bookempIcn from "../../assets/img/icons/customIcons/book-employee.svg";

import docIcon from "../../assets/img/icons/customIcons/document.svg";
import approveIcn from "../../assets/img/icons/customIcons/approve.svg";
import React, { useState } from "react";
import { capitilizeFirstAlphabet } from "helpers/stringManupulate";
import WorkingIcon from "../../assets/img/icons/common/working.png";
import BenchIcon from "../../assets/img/icons/common/bench.png";
import ContractIcon from "../../assets/img/icons/common/contract.png";

// import { baseURL } from "api/Request";

const Tables = ({
  isExt,
  isPreClosure,
  data,
  coloums,
  actionButtons,
  expiryTable,
  isAllRequest,
  isAllBooking,
  loader,
  isBookEmployee,
  isEmployeeStatus,
  isManager,
  isEmployeeUnavailable,
}) => {
  console.log(actionButtons, "actionButtons");
  const [, setButtonLoader] = useState(false);
  const [, setDataArr] = useState([]);
  const [orderArr, setOrderArr] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState(coloums[0]?.columnTag);
  // eslint-disable-next-line
  const [actionButtonPending, setActionButtonPending] = useState(
    actionButtons?.filter(
      (itm) => itm?.btn === "ViewBooking" || itm.btn === "PendingBookingDelete"
    )
  );

  const [actionButtonExtPending, setActionButtonExtPending] = useState(
    actionButtons?.filter(
      (itm) => itm?.btn === "ViewBooking" || itm.btn === "BookingExtentionDelete"    //outbound -pending extension req
    )
  );
  const [actionButtonExtention, setActionButtonExtention] = useState(      //outbound -all extension req 
    actionButtons?.filter(
      (itm) => itm?.btn === "ViewBooking" 
    )
  );
 
  const [actionButtonPreclosure, setActionButtonPreclosure] = useState(    //outbound -all preclosure req
    actionButtons?.filter(
      (itm) => itm?.btn === "ViewBooking" 
    )
  );

  const [actionButtonPreclosurePending, setActionButtonPreclosurePending] = useState(    //outbound -pending preclosure req
    actionButtons?.filter(
      (itm) => itm?.btn === "ViewBooking" ||itm.btn === "PendingBookingDelete"
    )
  );
  // eslint-disable-next-line
  const [actionButtonView, setactionButtonView] = useState(
    //only for view
    actionButtons?.filter(
      (itm) =>
        itm.btn === "ViewBooking" ||
        itm.btn === "ViewEmployee" ||
        itm.btn === "EmployeeBookingHistory"
    )
  );

  // eslint-disable-next-line
  const [actionButtonApprove, setActionButtonApprove] = useState(
    actionButtons?.filter(
      (itm) =>
        itm.btn === "ViewBooking" ||
        itm.btn === "ExtendBooking" ||
        itm.btn === "CloseBooking"
    )
  );
  // eslint-disable-next-line
  const [actionButtonSOW, setActionButtonSOW] = useState(
    actionButtons?.filter((itm) => itm.btn === "ViewBooking")
  );

  const handleActions = (btn, fn, item) => {
    switch (btn) {
      case "CompanyApproval":
        fn(item._id);
        setButtonLoader(true);

        break;
      case "CompanyReject":
        fn(item._id);
        setButtonLoader(true);

        break;
      case "EmployeeBookingHistory":
        fn(item, "view");
        setButtonLoader(true);

        break;
      case "EmployeeEdit":
        fn(item.employeeId, "edit");
        setButtonLoader(true);

        break;
      case "EmployeeDelete":
        fn(item._id, item, "delete");
        setButtonLoader(true);

        break;
      case "CompanyDelete":
        fn(item._id);
        setButtonLoader(true);

        break;
      case "BookEmployee":
        fn(item);
        setButtonLoader(true);

        break;
      case "PendingBookingDelete":
        fn(item._id, "delete");
        setButtonLoader(true);

        break;
      case "CloseBooking":
        const closeBookingData = {
          bookingId: item.bookingId,
          duration: item?.duration,
        };
        fn(closeBookingData);
        setButtonLoader(true);

        break;
      case "PendingBookingApproval":
        fn(item._id);
        setButtonLoader(true);

        break;
      case "PendingBookingReject":
        fn(item._id);
        setButtonLoader(true);

        break;
      case "SowApproval":
        fn(item._id);
        setButtonLoader(true);

        break;
      case "SowReject":
        fn(item._id);
        setButtonLoader(true);

        break;
      case "PreClousureRequestApproval":
        fn(item.bookingId);
        setButtonLoader(true);

        break;
      case "PreClousureRequestReject":
        fn(item.bookingId);
        setButtonLoader(true);

        break;
      case "ExtendBooking":
        const extendBookingObj = {
          employeeId: item.employeeId,
          isExtenstion: true,
          parentBookingId: item.bookingId,
          duration: item.duration,
        };
        fn(extendBookingObj);
        break;
      case "BookingExtentionApproval":
        fn(item?.bookingId);
        break;
      case "BookingExtentionReject":
        fn(item?.bookingId);
        break;
      case "BookingExtentionDelete":
        fn(item?.bookingId);
        break;
      case "ManagerEdit":
        fn(item?.userId);
        break;
      case "ManagerDelete":
        fn(item?.userId);
        break;
      case "CompanyHistory":
        fn(item, "view");
        setButtonLoader(true);
        break;
      case "ViewEmployee":
        fn(item);
        setButtonLoader(true);
        break;
      case "ViewBooking":
        fn(item);
        setButtonLoader(true);
        break;
      case "AdminDetails":
        fn(item);
        setButtonLoader(true);
        break;

      default:
        break;
    }
  };

  const sortArr = (val, type) => {
    if (type === "data" || type === "paymentMapping") {
      if (orderArr) {
        const sortedArr = data.sort((a, b) =>
          val !== "hourlyPayment" ? (a[val] > b[val] ? 1 : -1) : a[val] - b[val]
        );
        setDataArr(sortedArr);
      } else {
        const sortedArr = data.sort((a, b) =>
          val !== "hourlyPayment" ? (a[val] < b[val] ? 1 : -1) : b[val] - a[val]
        );
        setDataArr(sortedArr);
      }

      setOrderArr(!orderArr);
    } else if (type === "objectData") {
      if (orderArr) {
        const sortedArr = data.sort((a, b) =>
          val !== "hourlyPayment"
            ? a[val].fromDate > b[val].fromDate
              ? 1
              : -1
            : a[val].fromDate - b[val].fromDate
        );
        setDataArr(sortedArr);
      } else {
        const sortedArr = data.sort((a, b) =>
          val !== "hourlyPayment"
            ? a[val].fromDate < b[val].fromDate
              ? 1
              : -1
            : b[val].fromDate - a[val].fromDate
        );
        setDataArr(sortedArr);
      }
      setOrderArr(!orderArr);
    }
  };

  const backgroundColor = (item) => {
    if (!expiryTable) return;

    if (item.duration) {
      const nowDate = new Date();
      const toDate = new Date(item.duration.toDate);
      const dateDifference = toDate - nowDate;

      const fiveDays = 3600 * 24 * 5 * 1000;
      const tenDays = 3600 * 24 * 10 * 1000;

      if (dateDifference < 0) return;

      if (dateDifference < fiveDays) {
        return "#fcece8";
      }
      if (dateDifference > fiveDays && dateDifference < tenDays) {
        return "#f9fcd6";
      }
    }
  };

  const daysTillExpiry = (item) => {
    if (item.duration) {
      const nowDate = new Date();
      const toDate = new Date(item.duration.toDate);

      const dateDifference = toDate - nowDate;
      const days = Math.ceil(dateDifference / (3600 * 24 * 1000));
      return days;
    }
  };

  // const getStatusClass = (value) => {
  //   switch (value) {
  //     case "Booked": //current booking
  //       return "btn-status  bg-orange";
  //       // eslint-disable-next-line
  //       break;
  //     case "Booked Ahead": //future booking
  //       return "btn-status bg-yellow";
  //       // eslint-disable-next-line
  //       break;
  //     case "Not Booked": // f
  //       return "btn-status bg-green";
  //       // eslint-disable-next-line
  //       break;

  //     default:
  //       return "btn-status bg-green";
  //       // eslint-disable-next-line
  //       break;
  //   }
  // };

  // var src;
  const getStatusImage = (bookingstatus) => {
    console.log("book", bookingstatus);
    switch (bookingstatus) {
      case "Booked":
        return WorkingIcon;

      case "Not Booked":
        return BenchIcon;

      case "Booked Ahead":
        return ContractIcon;

      default:
        return BenchIcon;
    }
  };
  return (
    <>
      {/* Page content */}
      {/* Table */}
      <Card className="shadow mt-1 ">
        <>
          <Table
            className="table-flush"
            hover
            responsive
            style={{ verticalAlign: "middle" }}
          >
            <thead>
              <tr>
                {coloums?.map((item, key) => (
                  <th
                    style={{ cursor: "pointer" }}
                    key={key}
                    onClick={() =>
                      item?.columnTag !== ""
                        ? setSelectedColumn(item?.columnTag)
                        : ""
                    }
                  >
                    {item.columnName}
                    {selectedColumn === null ? (
                      <>
                        {item?.type === "data" ||
                        item?.type === "objectData" ||
                        item?.type === "paymentMapping" ? (
                          <>
                            <Button
                              className="up-down-button"
                              onClick={() =>
                                sortArr(item?.columnTag, item?.type)
                              }
                            >
                              <img
                                src={updownimg}
                                width="10"
                                className="ml-"
                                alt=""
                              ></img>
                            </Button>
                          </>
                        ) : (
                          <Button
                            className="up-down-button"
                            onClick={() => sortArr(item?.columnTag, item?.type)}
                          ></Button>
                        )}
                      </>
                    ) : (
                      <>
                        {selectedColumn === item?.columnTag &&
                          (item?.type === "data" ||
                            item?.type === "objectData" ||
                            item?.type === "paymentMapping") && (
                            <>
                              <Button
                                className="up-down-button"
                                onClick={() =>
                                  sortArr(item?.columnTag, item?.type)
                                }
                              >
                                <img
                                  src={updownimg}
                                  width="13"
                                  className="ml-2"
                                  alt=""
                                ></img>
                              </Button>
                            </>
                          )}
                      </>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            {!loader && (
              <tbody>
                {data &&
                  data?.length > 0 &&
                  data.map((item, key) => (
                    <tr
                      key={key}
                      style={{
                        backgroundColor: backgroundColor(item),
                      }}
                    >
                      {coloums.map((coloum, index) => (
                        <>
                          <>
                            {coloum.columnTag === "BookEmployee" && (
                              <>
                                <td style={{ verticalAlign: "middle" }}>
                                  <Button
                                    style={{ paddingLeft: "50px" }}
                                    type="submit"
                                    className="eyeicon-btn pb-4"
                                    color="primary"
                                    onClick={() =>
                                      coloum?.actionBookEmployee(item)
                                    }
                                  >
                                    <img alt="..." src={bookempIcn} />
                                  </Button>
                                </td>
                              </>
                            )}
                            {coloum.type === "data" && (
                              <>
                                {coloum?.columnTag === "createdAt" ? (
                                  <td
                                    onClick={() => {
                                      !isManager &&
                                        handleActions(
                                          actionButtons[0].btn,
                                          actionButtons[0].actionFunction,
                                          item
                                        );
                                    }}
                                    key={index}
                                    style={{
                                      verticalAlign: "middle",
                                      cursor: !isManager && "pointer",
                                    }}
                                  >
                                    {moment(item[coloum.columnTag]).format(
                                      "MMMM DD, YYYY"
                                    )}
                                  </td>
                                ) : (
                                  <>
                                    {coloum?.columnTag === "daysTillExpiry" && (
                                      <td
                                        onClick={() => {
                                          handleActions(
                                            actionButtonView[0].btn,
                                            actionButtonView[0].actionFunction,
                                            item
                                          );
                                        }}
                                        style={{
                                          verticalAlign: "middle",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {daysTillExpiry(item)}
                                      </td>
                                    )}

                                    {coloum?.columnTag ===
                                    "sow_final_status" ? (
                                      <td
                                        style={{ verticalAlign: "middle" }}
                                        key={index}
                                      >
                                        {(item["status"] === "SOW Pending" ||
                                          item["status"] === "SOW Rejected" ||
                                          item["status"] ===
                                            "SOW Uploaded") && (
                                          // eslint-disable-next-line react/jsx-no-target-blank
                                          <Button
                                            type="submit"
                                            className="eyeicon-btn pb-4"
                                            color="primary"
                                            onClick={() =>
                                              coloum?.actionSOWApprove(
                                                item._id,
                                                item,
                                                null
                                              )
                                            }
                                          >
                                            <img alt="..." src={disabledDoc} />
                                          </Button>
                                        )}
                                        {(item["status"] === "approved" ||
                                          item["status"] === "completed") && (
                                          // eslint-disable-next-line react/jsx-no-target-blank
                                          <Button
                                            type="submit"
                                            className="eyeicon-btn pb-4"
                                            color="primary"
                                            onClick={() =>
                                              coloum?.actionSOWApprove(
                                                item._id,
                                                item,
                                                "isUpload"
                                              )
                                            }
                                          >
                                            <img alt="..." src={docIcon} />
                                          </Button>
                                        )}

                                        {(item["status"] === "pending" ||
                                          item["status"] === "rejected") && (
                                          // eslint-disable-next-line react/jsx-no-target-blank
                                          <span style={{ paddingLeft: "10px" }}>
                                            --
                                          </span>
                                        )}
                                      </td>
                                    ) : (
                                      <td
                                        className={
                                          coloum?.columnTag === "name"
                                            ? "text-to-ellipse"
                                            : null
                                        }
                                        onClick={() => {
                                          !isManager &&
                                            handleActions(
                                              actionButtons[0].btn,
                                              actionButtons[0].actionFunction,
                                              item
                                            );
                                        }}
                                        key={index}
                                        // style={{ verticalAlign: "middle" }}

                                        style={{
                                          verticalAlign: "middle",
                                          cursor: !isManager && "pointer",
                                        }}
                                      >
                                        {item[coloum.columnTag] &&
                                          (coloum.columnTag === "email" ? ( //for email
                                            item[coloum.columnTag].toString()
                                          ) : coloum.columnTag === "name" &&
                                            isEmployeeStatus ? (
                                            <div className="d-flex justify-content-start align-items-center gap-3">
                                              <div
                                                style={{
                                                  marginTop: "-2px",
                                                  marginRight: "6px",
                                                }}
                                                // className={getStatusClass(
                                                //   item?.booking_status
                                                // )}
                                                data-toggle="tooltip"
                                                title={
                                                  item?.booking_status
                                                    ? capitilizeFirstAlphabet(
                                                        item?.booking_status
                                                      )
                                                    : "Not Booked"
                                                }
                                              >
                                                <img
                                                  alt=""
                                                  src={getStatusImage(
                                                    item?.booking_status
                                                  )}
                                                  width={"8px"}
                                                />
                                              </div>

                                              <div
                                                className="text-to-ellipse"
                                                data-toggle="tooltip"
                                                title={capitilizeFirstAlphabet(
                                                  item.name
                                                )}
                                              >
                                                {capitilizeFirstAlphabet(
                                                  item.name
                                                )}
                                              </div>
                                            </div>
                                          ) : (
                                            capitilizeFirstAlphabet(
                                              item[coloum.columnTag].toString()
                                            )
                                          ))}
                                      </td>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                          <>
                            {coloum.type === "toggle" && (
                              <td
                                key={index}
                                style={{ verticalAlign: "middle" }}
                              >
                                <Toggle
                                  checked={item?.status}
                                  editEmployeeStatus={coloum.actionFunction}
                                  id={item._id}
                                  key={key}
                                  offstyle="btn-danger"
                                  onstyle="btn-success"
                                />
                              </td>
                            )}
                          </>
                          <>
                            {coloum.type === "mapping" && (
                              <td
                                onClick={() => {
                                  handleActions(
                                    actionButtonView[0].btn,
                                    actionButtonView[0].actionFunction,
                                    item
                                  );
                                }}
                                key={index}
                                style={{
                                  maxWidth: "150px",
                                  whiteSpace: "break-spaces",
                                  verticalAlign: "middle",
                                  cursor: "pointer",
                                }}
                              >
                                {item?.skillSets?.map((skill, index) => (
                                  <div
                                    style={{
                                      borderRadius: "8px",
                                      background: "rgb(148 166 242 / 24%)",

                                      display: "inline-block",
                                      padding: "4px 8px ",
                                      marginRight: "6px",
                                      marginBottom: "6px",
                                    }}
                                    key={index}
                                    className="h5 font-weight-normal"
                                  >
                                    {skill.domain}
                                    {/* - {skill.numberOfYears} years{" "}
                                {skill.numberOfMonths} months */}
                                  </div>
                                ))}
                              </td>
                            )}
                          </>
                          <>
                            {coloum.type === "text" && (
                              <td
                                key={index}
                                style={{
                                  verticalAlign: "middle",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleActions(
                                    actionButtonView[0].btn,
                                    actionButtonView[0].actionFunction,
                                    item
                                  );
                                }}
                              >
                                <p
                                  key={index}
                                  className="h5 font-weight-normal mb-0"
                                >
                                  {item["experience"].numberOfMonths !== 0
                                    ? item["experience"].numberOfYears +
                                      "." +
                                      item["experience"].numberOfMonths +
                                      " " +
                                      "Years"
                                    : item["experience"].numberOfYears > 1
                                    ? item["experience"].numberOfYears +
                                      " " +
                                      "Years"
                                    : item["experience"].numberOfYears +
                                      " " +
                                      "Year"}
                                </p>
                              </td>
                            )}
                          </>
                          <>
                            {coloum.type === "paymentMapping" && (
                              <td
                                onClick={() => {
                                  handleActions(
                                    actionButtons[0].btn,
                                    actionButtons[0].actionFunction,
                                    item
                                  );
                                }}
                                key={index}
                                style={{
                                  verticalAlign: "middle",
                                  cursor: "pointer",
                                }}
                              >
                                <p
                                  key={index}
                                  className="h5 font-weight-normal mb-0"
                                >
                                  {/* {item[coloum.columnTag] &&
                                capitilizeFirstAlphabet(
                                  item[coloum.columnTag].toString()
                                )}{" "}
                              {item["currencyCode"] &&
                                capitilizeFirstAlphabet(
                                  item["currencyCode"].toString()
                                )} */}

                                  {item["currencyCode"] &&
                                    (() => {
                                      const currencyCode =
                                        item["currencyCode"].toString();
                                      let currencySymbol;

                                      if (currencyCode === "USD") {
                                        currencySymbol = "$";
                                      } else if (currencyCode === "EUR") {
                                        currencySymbol = "€";
                                      } else if (currencyCode === "INR") {
                                        currencySymbol = "₹";
                                      } else {
                                        currencySymbol = currencyCode;
                                      }

                                      return currencySymbol;
                                    })()}

                                  {item[coloum.columnTag] &&
                                    capitilizeFirstAlphabet(
                                      item[coloum.columnTag].toString()
                                    )}
                                </p>
                              </td>
                            )}
                          </>
                          <>
                            {coloum.type === "objectData" && (
                              <>
                                <td
                                  onClick={() => {
                                    handleActions(
                                      actionButtonView[0].btn,
                                      actionButtonView[0].actionFunction,
                                      item
                                    );
                                  }}
                                  key={index}
                                  style={{
                                    verticalAlign: "middle",
                                    cursor: "pointer",
                                  }}
                                >
                                  {moment(item["duration"].fromDate).format(
                                    "MMMM DD, YYYY"
                                  )}{" "}
                                  -{" "}
                                  {moment(item["duration"].toDate).format(
                                    "MMMM DD, YYYY"
                                  )}
                                </td>
                              </>
                            )}
                          </>
                        </>
                      ))}
                      {!isBookEmployee && (
                        <td style={{ verticalAlign: "middle", width: "20px" }}>
                          {actionButtons && actionButtons.length > 0 && (
                            <>
                              <div class="dropdown ">
                                <button
                                  class="btn  cstm-iconBtn"
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i
                                    class="fa fa-ellipsis-h"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                <div
                                  class="dropdown-menu py-0"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  {item.status === "approved" &&
                                    isAllRequest &&
                                    !isAllBooking &&
                                    actionButtonApprove.map((col, key) => (
                                      <div className="cstm-dropdown">
                                        {col?.loading ? (
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            class="btn"
                                          >
                                            <img
                                              alt="..."
                                              className="table-icons"
                                              src={approveIcn}
                                            />
                                          </button>
                                        ) : (
                                          <Button
                                            className="cstm-tbl-actions"
                                            // data-toggle="tooltip"
                                            // data-placement="top"
                                            // title={col?.btnTooltip}
                                            disabled={
                                              (col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true") ||
                                              col.btnStatus
                                              // ||
                                              // (col.btn === "PendingBookingApproval" && col.disable) // disable condition for PendingBookingApproval
                                            }
                                            key={key}
                                            // disabled={buttonLoader}

                                            onClick={() => {
                                              handleActions(
                                                col.btn,
                                                col.actionFunction,
                                                item
                                              );
                                            }}
                                          >
                                            {col?.btnIcon ? (
                                              <img
                                                style={{ marginRight: "8px" }}
                                                className="table-icons"
                                                alt="..."
                                                src={col?.btnIcon}
                                              />
                                            ) : (
                                              <>
                                                {col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true"
                                                  ? "Extension requested"
                                                  : col.btnTitle}
                                              </>
                                            )}
                                            {col?.btnTooltip}
                                          </Button>
                                        )}
                                      </div>
                                    ))}

                                  {item.status === "approved" &&
                                    !isAllRequest &&
                                    isAllBooking &&
                                    actionButtonApprove.map((col, key) => (
                                      <div className="cstm-dropdown">
                                        {col?.loading ? (
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            class="btn"
                                          >
                                            <img
                                              alt="..."
                                              className="table-icons"
                                              src={approveIcn}
                                            />
                                          </button>
                                        ) : (
                                          <Button
                                            className="cstm-tbl-actions"
                                            // data-toggle="tooltip"
                                            // data-placement="top"
                                            // title={col?.btnTooltip}
                                            disabled={
                                              (col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true") ||
                                              col.btnStatus
                                              // ||
                                              // (col.btn === "PendingBookingApproval" && col.disable) // disable condition for PendingBookingApproval
                                            }
                                            key={key}
                                            // disabled={buttonLoader}

                                            onClick={() => {
                                              handleActions(
                                                col.btn,
                                                col.actionFunction,
                                                item
                                              );
                                            }}
                                          >
                                            {col?.btnIcon ? (
                                              <img
                                                style={{ marginRight: "8px" }}
                                                className="table-icons"
                                                alt="..."
                                                src={col?.btnIcon}
                                              />
                                            ) : (
                                              <>
                                                {col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true"
                                                  ? "Extension requested"
                                                  : col.btnTitle}
                                              </>
                                            )}
                                            {col?.btnTooltip}
                                          </Button>
                                        )}
                                      </div>
                                    ))}

                                  {(item.status === "rejected" ||
                                    item.status === "completed") &&
                                    isAllRequest &&
                                    !isAllBooking &&
                                    actionButtonView.map((col, key) => (
                                      <div className="cstm-dropdown">
                                        {col?.loading ? (
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            class="btn"
                                          >
                                            <img
                                              alt="..."
                                              className="table-icons"
                                              src={approveIcn}
                                            />
                                          </button>
                                        ) : (
                                          <Button
                                            className="cstm-tbl-actions"
                                            // data-toggle="tooltip"
                                            // data-placement="top"
                                            // title={col?.btnTooltip}
                                            disabled={
                                              (col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true") ||
                                              col.btnStatus
                                              // ||
                                              // (col.btn === "PendingBookingApproval" && col.disable) // disable condition for PendingBookingApproval
                                            }
                                            key={key}
                                            // disabled={buttonLoader}

                                            onClick={() => {
                                              handleActions(
                                                col.btn,
                                                col.actionFunction,
                                                item
                                              );
                                            }}
                                          >
                                            {col?.btnIcon ? (
                                              <img
                                                style={{ marginRight: "8px" }}
                                                className="table-icons"
                                                alt="..."
                                                src={col?.btnIcon}
                                              />
                                            ) : (
                                              <>
                                                {col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true"
                                                  ? "Extension requested"
                                                  : col.btnTitle}
                                              </>
                                            )}
                                            {col?.btnTooltip}
                                          </Button>
                                        )}
                                      </div>
                                    ))}

                                  {(item.status === "rejected" ||
                                    item.status === "completed") &&
                                    !isAllRequest &&
                                    isAllBooking &&
                                    actionButtonView.map((col, key) => (
                                      <div className="cstm-dropdown">
                                        {col?.loading ? (
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            class="btn"
                                          >
                                            <img
                                              alt="..."
                                              className="table-icons"
                                              src={approveIcn}
                                            />
                                          </button>
                                        ) : (
                                          <Button
                                            className="cstm-tbl-actions"
                                            // data-toggle="tooltip"
                                            // data-placement="top"
                                            // title={col?.btnTooltip}
                                            disabled={
                                              (col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true") ||
                                              col.btnStatus
                                              // ||
                                              // (col.btn === "PendingBookingApproval" && col.disable) // disable condition for PendingBookingApproval
                                            }
                                            key={key}
                                            // disabled={buttonLoader}

                                            onClick={() => {
                                              handleActions(
                                                col.btn,
                                                col.actionFunction,
                                                item
                                              );
                                            }}
                                          >
                                            {col?.btnIcon ? (
                                              <img
                                                style={{ marginRight: "8px" }}
                                                className="table-icons"
                                                alt="..."
                                                src={col?.btnIcon}
                                              />
                                            ) : (
                                              <>
                                                {col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true"
                                                  ? "Extension requested"
                                                  : col.btnTitle}
                                              </>
                                            )}
                                            {col?.btnTooltip}
                                          </Button>
                                        )}
                                      </div>
                                    ))}

                                  {item.status === "pending" &&
                                    isAllRequest &&
                                    !isAllBooking &&
                                    actionButtonPending.map((col, key) => (
                                      <div className="cstm-dropdown">
                                        {col?.loading ? (
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            class="btn"
                                          >
                                            <img
                                              alt="..."
                                              className="table-icons"
                                              src={approveIcn}
                                            />
                                          </button>
                                        ) : (
                                          <Button
                                            className="cstm-tbl-actions"
                                            // data-toggle="tooltip"
                                            // data-placement="top"
                                            // title={col?.btnTooltip}
                                            disabled={
                                              (col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true") ||
                                              col.btnStatus
                                              // ||
                                              // (col.btn === "PendingBookingApproval" && col.disable) // disable condition for PendingBookingApproval
                                            }
                                            key={key}
                                            // disabled={buttonLoader}

                                            onClick={() => {
                                              handleActions(
                                                col.btn,
                                                col.actionFunction,
                                                item
                                              );
                                            }}
                                          >
                                            {col?.btnIcon ? (
                                              <img
                                                style={{ marginRight: "8px" }}
                                                className="table-icons"
                                                alt="..."
                                                src={col?.btnIcon}
                                              />
                                            ) : (
                                              <>
                                                {col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true"
                                                  ? "Extension requested"
                                                  : col.btnTitle}
                                              </>
                                            )}
                                            {col?.btnTooltip}
                                          </Button>
                                        )}
                                      </div>
                                    ))}

                                  {isAllBooking &&
                                    !isAllRequest &&
                                    item.status === "pending" &&
                                    actionButtons.map((col, key) => (
                                      <div className="cstm-dropdown">
                                        {col?.loading ? (
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            class="btn"
                                          >
                                            <img
                                              alt="..."
                                              className="table-icons"
                                              src={approveIcn}
                                            />
                                          </button>
                                        ) : (
                                          <Button
                                            className="cstm-tbl-actions"
                                            // data-toggle="tooltip"
                                            // data-placement="top"
                                            // title={col?.btnTooltip}
                                            disabled={
                                              (col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true") ||
                                              col.btnStatus
                                              // ||
                                              // (col.btn === "PendingBookingApproval" && col.disable) // disable condition for PendingBookingApproval
                                            }
                                            key={key}
                                            // disabled={buttonLoader}

                                            onClick={() => {
                                              handleActions(
                                                col.btn,
                                                col.actionFunction,
                                                item
                                              );
                                            }}
                                          >
                                            {col?.btnIcon ? (
                                              <img
                                                style={{ marginRight: "8px" }}
                                                className="table-icons"
                                                alt="..."
                                                src={col?.btnIcon}
                                              />
                                            ) : (
                                              <>
                                                {col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true"
                                                  ? "Extension requested"
                                                  : col.btnTitle}
                                              </>
                                            )}
                                            {col?.btnTooltip}
                                          </Button>
                                        )}
                                      </div>
                                    ))}

                                  {!isAllRequest &&
                                    !isAllBooking &&
                                    !isExt &&
                                    !isPreClosure &&
                                    actionButtons.map((col, key) => (
                                      <div className="cstm-dropdown">
                                        {col?.loading ? (
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            class="btn"
                                          >
                                            <img
                                              alt="..."
                                              className="table-icons"
                                              src={approveIcn}
                                            />
                                          </button>
                                        ) : (
                                          <Button
                                            className="cstm-tbl-actions"
                                            // data-toggle="tooltip"
                                            // data-placement="top"
                                            // title={col?.btnTooltip}
                                            disabled={
                                              (col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true") ||
                                              col.btnStatus
                                              // ||
                                              // (col.btn === "PendingBookingApproval" && col.disable) // disable condition for PendingBookingApproval
                                            }
                                            key={key}
                                            // disabled={buttonLoader}

                                            onClick={() => {
                                              handleActions(
                                                col.btn,
                                                col.actionFunction,
                                                item
                                              );
                                            }}
                                          >
                                            {col?.btnIcon ? (
                                              <img
                                                style={{ marginRight: "8px" }}
                                                className="table-icons"
                                                alt="..."
                                                src={col?.btnIcon}
                                              />
                                            ) : (
                                              <>
                                                {col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true"
                                                  ? "Extension requested"
                                                  : col.btnTitle}
                                              </>
                                            )}
                                            {col?.btnTooltip}
                                          </Button>
                                        )}
                                      </div>
                                    ))}

                                  {!isAllRequest && //outbound - all extension req
                                    !isAllBooking &&
                                    isExt &&
                                    item.extentionStatus !== "pending" &&
                                    actionButtonExtention.map((col, key) => (
                                      <div className="cstm-dropdown">
                                        {col?.loading ? (
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            class="btn"
                                          >
                                            <img
                                              alt="..."
                                              className="table-icons"
                                              src={approveIcn}
                                            />
                                          </button>
                                        ) : (
                                          <Button
                                            className="cstm-tbl-actions"
                                            disabled={
                                              (col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true") ||
                                              col.btnStatus
                                              // ||
                                              // (col.btn === "PendingBookingApproval" && col.disable) // disable condition for PendingBookingApproval
                                            }
                                            key={key}
                                            // disabled={buttonLoader}

                                            onClick={() => {
                                              handleActions(
                                                col.btn,
                                                col.actionFunction,
                                                item
                                              );
                                            }}
                                          >
                                            {col?.btnIcon ? (
                                              <img
                                                style={{ marginRight: "8px" }}
                                                className="table-icons"
                                                alt="..."
                                                src={col?.btnIcon}
                                              />
                                            ) : (
                                              <>
                                                {col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true"
                                                  ? "Extension requested"
                                                  : col.btnTitle}
                                              </>
                                            )}
                                            {col?.btnTooltip}
                                          </Button>
                                        )}
                                      </div>
                                    ))}

                                  {!isAllRequest && //outbound - pending extension req
                                    !isAllBooking &&
                                    isExt &&
                                    item.extentionStatus === "pending" &&
                                    actionButtonExtPending.map((col, key) => (
                                      <div className="cstm-dropdown">
                                        {col?.loading ? (
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            class="btn"
                                          >
                                            <img
                                              alt="..."
                                              className="table-icons"
                                              src={approveIcn}
                                            />
                                          </button>
                                        ) : (
                                          <Button
                                            className="cstm-tbl-actions"
                                            // data-toggle="tooltip"
                                            // data-placement="top"
                                            // title={col?.btnTooltip}
                                            disabled={
                                              (col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true") ||
                                              col.btnStatus
                                              // ||
                                              // (col.btn === "PendingBookingApproval" && col.disable) // disable condition for PendingBookingApproval
                                            }
                                            key={key}
                                            // disabled={buttonLoader}

                                            onClick={() => {
                                              handleActions(
                                                col.btn,
                                                col.actionFunction,
                                                item
                                              );
                                            }}
                                          >
                                            {col?.btnIcon ? (
                                              <img
                                                style={{ marginRight: "8px" }}
                                                className="table-icons"
                                                alt="..."
                                                src={col?.btnIcon}
                                              />
                                            ) : (
                                              <>
                                                {col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true"
                                                  ? "Extension requested"
                                                  : col.btnTitle}
                                              </>
                                            )}
                                            {col?.btnTooltip}
                                          </Button>
                                        )}
                                      </div>
                                    ))}
                                  {!isAllRequest && //outbound - pending preclosure req
                                    !isAllBooking &&
                                    isPreClosure &&
                                    item.preclosureStatus === "pending" &&
                                    actionButtonPreclosurePending.map(
                                      (col, key) => (
                                        <div className="cstm-dropdown">
                                          {col?.loading ? (
                                            <button
                                              type="button"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              class="btn"
                                            >
                                              <img
                                                alt="..."
                                                className="table-icons"
                                                src={approveIcn}
                                              />
                                            </button>
                                          ) : (
                                            <Button
                                              className="cstm-tbl-actions"
                                              disabled={
                                                (col.btn === "ExtendBooking" &&
                                                  item.extentionRequested ===
                                                    "true") ||
                                                col.btnStatus
                                                // ||
                                                // (col.btn === "PendingBookingApproval" && col.disable) // disable condition for PendingBookingApproval
                                              }
                                              key={key}
                                              // disabled={buttonLoader}

                                              onClick={() => {
                                                handleActions(
                                                  col.btn,
                                                  col.actionFunction,
                                                  item
                                                );
                                              }}
                                            >
                                              {col?.btnIcon ? (
                                                <img
                                                  style={{ marginRight: "8px" }}
                                                  className="table-icons"
                                                  alt="..."
                                                  src={col?.btnIcon}
                                                />
                                              ) : (
                                                <>
                                                  {col.btn ===
                                                    "ExtendBooking" &&
                                                  item.extentionRequested ===
                                                    "true"
                                                    ? "Extension requested"
                                                    : col.btnTitle}
                                                </>
                                              )}
                                              {col?.btnTooltip}
                                            </Button>
                                          )}
                                        </div>
                                      )
                                    )}

                                  {!isAllRequest && //outbound - all preclosure req
                                    !isAllBooking &&
                                    isPreClosure &&
                                    item.preclosureStatus !== "pending" &&
                                    actionButtonPreclosure.map((col, key) => (
                                      <div className="cstm-dropdown">
                                        {col?.loading ? (
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            class="btn"
                                          >
                                            <img
                                              alt="..."
                                              className="table-icons"
                                              src={approveIcn}
                                            />
                                          </button>
                                        ) : (
                                          <Button
                                            className="cstm-tbl-actions"
                                            disabled={
                                              (col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true") ||
                                              col.btnStatus
                                              // ||
                                              // (col.btn === "PendingBookingApproval" && col.disable) // disable condition for PendingBookingApproval
                                            }
                                            key={key}
                                            // disabled={buttonLoader}

                                            onClick={() => {
                                              handleActions(
                                                col.btn,
                                                col.actionFunction,
                                                item
                                              );
                                            }}
                                          >
                                            {col?.btnIcon ? (
                                              <img
                                                style={{ marginRight: "8px" }}
                                                className="table-icons"
                                                alt="..."
                                                src={col?.btnIcon}
                                              />
                                            ) : (
                                              <>
                                                {col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true"
                                                  ? "Extension requested"
                                                  : col.btnTitle}
                                              </>
                                            )}
                                            {col?.btnTooltip}
                                          </Button>
                                        )}
                                      </div>
                                    ))}
                                  {isAllBooking &&
                                    !isAllRequest &&
                                    item.status === "SOW Pending" &&
                                    actionButtonSOW.map((col, key) => (
                                      <div className="cstm-dropdown">
                                        {col?.loading ? (
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            class="btn"
                                          >
                                            <img
                                              alt="..."
                                              className="table-icons"
                                              src={approveIcn}
                                            />
                                          </button>
                                        ) : (
                                          <Button
                                            className="cstm-tbl-actions"
                                            // data-toggle="tooltip"
                                            // data-placement="top"
                                            // title={col?.btnTooltip}
                                            disabled={
                                              (col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true") ||
                                              col.btnStatus
                                              // ||
                                              // (col.btn === "PendingBookingApproval" && col.disable) // disable condition for PendingBookingApproval
                                            }
                                            key={key}
                                            // disabled={buttonLoader}

                                            onClick={() => {
                                              handleActions(
                                                col.btn,
                                                col.actionFunction,
                                                item
                                              );
                                            }}
                                          >
                                            {col?.btnIcon ? (
                                              <img
                                                style={{ marginRight: "8px" }}
                                                className="table-icons"
                                                alt="..."
                                                src={col?.btnIcon}
                                              />
                                            ) : (
                                              <>
                                                {col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true"
                                                  ? "Extension requested"
                                                  : col.btnTitle}
                                              </>
                                            )}
                                            {col?.btnTooltip}
                                          </Button>
                                        )}
                                      </div>
                                    ))}

                                  {!isAllBooking &&
                                    isAllRequest &&
                                    item.status === "SOW Pending" &&
                                    actionButtonSOW.map((col, key) => (
                                      <div className="cstm-dropdown">
                                        {col?.loading ? (
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            class="btn"
                                          >
                                            <img
                                              alt="..."
                                              className="table-icons"
                                              src={approveIcn}
                                            />
                                          </button>
                                        ) : (
                                          <Button
                                            className="cstm-tbl-actions"
                                            // data-toggle="tooltip"
                                            // data-placement="top"
                                            // title={col?.btnTooltip}
                                            disabled={
                                              (col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true") ||
                                              col.btnStatus
                                              // ||
                                              // (col.btn === "PendingBookingApproval" && col.disable) // disable condition for PendingBookingApproval
                                            }
                                            key={key}
                                            // disabled={buttonLoader}

                                            onClick={() => {
                                              handleActions(
                                                col.btn,
                                                col.actionFunction,
                                                item
                                              );
                                            }}
                                          >
                                            {col?.btnIcon ? (
                                              <img
                                                style={{ marginRight: "8px" }}
                                                className="table-icons"
                                                alt="..."
                                                src={col?.btnIcon}
                                              />
                                            ) : (
                                              <>
                                                {col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true"
                                                  ? "Extension requested"
                                                  : col.btnTitle}
                                              </>
                                            )}
                                            {col?.btnTooltip}
                                          </Button>
                                        )}
                                      </div>
                                    ))}

                                  {!isAllBooking &&
                                    isAllRequest &&
                                    item.status === "SOW Rejected" &&
                                    actionButtonSOW.map((col, key) => (
                                      <div className="cstm-dropdown">
                                        {col?.loading ? (
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            class="btn"
                                          >
                                            <img
                                              alt="..."
                                              className="table-icons"
                                              src={approveIcn}
                                            />
                                          </button>
                                        ) : (
                                          <Button
                                            className="cstm-tbl-actions"
                                            // data-toggle="tooltip"
                                            // data-placement="top"
                                            // title={col?.btnTooltip}
                                            disabled={
                                              (col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true") ||
                                              col.btnStatus
                                              // ||
                                              // (col.btn === "PendingBookingApproval" && col.disable) // disable condition for PendingBookingApproval
                                            }
                                            key={key}
                                            // disabled={buttonLoader}

                                            onClick={() => {
                                              handleActions(
                                                col.btn,
                                                col.actionFunction,
                                                item
                                              );
                                            }}
                                          >
                                            {col?.btnIcon ? (
                                              <img
                                                style={{ marginRight: "8px" }}
                                                className="table-icons"
                                                alt="..."
                                                src={col?.btnIcon}
                                              />
                                            ) : (
                                              <>
                                                {col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true"
                                                  ? "Extension requested"
                                                  : col.btnTitle}
                                              </>
                                            )}
                                            {col?.btnTooltip}
                                          </Button>
                                        )}
                                      </div>
                                    ))}

                                  {isAllBooking &&
                                    !isAllRequest &&
                                    item.status === "SOW Rejected" &&
                                    actionButtonSOW.map((col, key) => (
                                      <div className="cstm-dropdown">
                                        {col?.loading ? (
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            class="btn"
                                          >
                                            <img
                                              alt="..."
                                              className="table-icons"
                                              src={approveIcn}
                                            />
                                          </button>
                                        ) : (
                                          <Button
                                            className="cstm-tbl-actions"
                                            // data-toggle="tooltip"
                                            // data-placement="top"
                                            // title={col?.btnTooltip}
                                            disabled={
                                              (col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true") ||
                                              col.btnStatus
                                              // ||
                                              // (col.btn === "PendingBookingApproval" && col.disable) // disable condition for PendingBookingApproval
                                            }
                                            key={key}
                                            // disabled={buttonLoader}

                                            onClick={() => {
                                              handleActions(
                                                col.btn,
                                                col.actionFunction,
                                                item
                                              );
                                            }}
                                          >
                                            {col?.btnIcon ? (
                                              <img
                                                style={{ marginRight: "8px" }}
                                                className="table-icons"
                                                alt="..."
                                                src={col?.btnIcon}
                                              />
                                            ) : (
                                              <>
                                                {col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true"
                                                  ? "Extension requested"
                                                  : col.btnTitle}
                                              </>
                                            )}
                                            {col?.btnTooltip}
                                          </Button>
                                        )}
                                      </div>
                                    ))}

                                  {isAllBooking &&
                                    !isAllRequest &&
                                    item.status === "SOW Uploaded" &&
                                    actionButtonSOW.map((col, key) => (
                                      <div className="cstm-dropdown">
                                        {col?.loading ? (
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            class="btn"
                                          >
                                            <img
                                              alt="..."
                                              className="table-icons"
                                              src={approveIcn}
                                            />
                                          </button>
                                        ) : (
                                          <Button
                                            className="cstm-tbl-actions"
                                            // data-toggle="tooltip"
                                            // data-placement="top"
                                            // title={col?.btnTooltip}
                                            disabled={
                                              (col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true") ||
                                              col.btnStatus
                                              // ||
                                              // (col.btn === "PendingBookingApproval" && col.disable) // disable condition for PendingBookingApproval
                                            }
                                            key={key}
                                            // disabled={buttonLoader}

                                            onClick={() => {
                                              handleActions(
                                                col.btn,
                                                col.actionFunction,
                                                item
                                              );
                                            }}
                                          >
                                            {col?.btnIcon ? (
                                              <img
                                                style={{ marginRight: "8px" }}
                                                className="table-icons"
                                                alt="..."
                                                src={col?.btnIcon}
                                              />
                                            ) : (
                                              <>
                                                {col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true"
                                                  ? "Extension requested"
                                                  : col.btnTitle}
                                              </>
                                            )}
                                            {col?.btnTooltip}
                                          </Button>
                                        )}
                                      </div>
                                    ))}

                                  {!isAllBooking &&
                                    isAllRequest &&
                                    item.status === "SOW Uploaded" &&
                                    actionButtonSOW.map((col, key) => (
                                      <div className="cstm-dropdown">
                                        {col?.loading ? (
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            class="btn"
                                          >
                                            <img
                                              alt="..."
                                              className="table-icons"
                                              src={approveIcn}
                                            />
                                          </button>
                                        ) : (
                                          <Button
                                            className="cstm-tbl-actions"
                                            // data-toggle="tooltip"
                                            // data-placement="top"
                                            // title={col?.btnTooltip}
                                            disabled={
                                              (col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true") ||
                                              col.btnStatus
                                              // ||
                                              // (col.btn === "PendingBookingApproval" && col.disable) // disable condition for PendingBookingApproval
                                            }
                                            key={key}
                                            // disabled={buttonLoader}

                                            onClick={() => {
                                              handleActions(
                                                col.btn,
                                                col.actionFunction,
                                                item
                                              );
                                            }}
                                          >
                                            {col?.btnIcon ? (
                                              <img
                                                style={{ marginRight: "8px" }}
                                                className="table-icons"
                                                alt="..."
                                                src={col?.btnIcon}
                                              />
                                            ) : (
                                              <>
                                                {col.btn === "ExtendBooking" &&
                                                item.extentionRequested ===
                                                  "true"
                                                  ? "Extension requested"
                                                  : col.btnTitle}
                                              </>
                                            )}
                                            {col?.btnTooltip}
                                          </Button>
                                        )}
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            )}
          </Table>

          {loader && (
            <div className="table-loader">
              <img
                alt="..."
                src={
                  require("../../assets/img/icons/customIcons/loader.gif")
                    .default
                }
              />
            </div>
          )}

          {data?.length === 0 && !loader && !isEmployeeUnavailable && (
            <div className="nodata-table">
              <img
                alt="..."
                src={
                  require("../../assets/img/icons/customIcons/nodata.gif")
                    .default
                }
              />
            </div>
          )}

          {isEmployeeUnavailable && (
            <div className="nodata-table">
              <p className="text-danger" style={{ fontWeight: "500" }}>
                Sorry the employee is unavailable
              </p>
            </div>
          )}
        </>
      </Card>
    </>
  );
};

export default Tables;
