import React from "react";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { Rating } from "react-simple-star-rating";
import moment from "moment";
import { baseURL } from "api/Request";
import downloadIcn from "../../assets/img/icons/customIcons/download-svg.svg";

const BookingModal = ({ modal, close, data, isEmployee }) => {
  const onClose = () => {
    close();
  };
  console.log("emply Detls ", data);
  return (
    <div>
      {!isEmployee ? (
        <>
          <Modal
            toggle={close}
            scrollable
            isOpen={modal}
            className="bookingmodal rightside-modal"
          >
            <Row>
              <div className="col">
                <ModalHeader className="text-uppercase font-weight-bolder pb-2">
                  <h2>Employee Details</h2>
                </ModalHeader>
              </div>
              <Row>
                <Col>
                  <ModalHeader className="text-uppercase pt-3 pb-2">
                    <Button onClick={onClose} className="mx-1" close />
                  </ModalHeader>
                </Col>
              </Row>
            </Row>
            <ModalBody>
              <FormGroup row className="employee-det-row mb-0 px-2">
                <Col sm={6} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Employee ID </h4>
                    </div>
                    <div className="values">
                      <p>{data?.empId}</p>
                    </div>
                  </div>
                </Col>
                {data?.sowStatus === "approved" && (
                  <Col sm={6} className="mb-2 px-1">
                    <div className="first-row">
                      <div className="labels">
                        <h4>Employee Name </h4>
                      </div>
                      <div className="values text-capitalize">
                        <p>{data?.name}</p>
                      </div>
                    </div>
                  </Col>
                )}

                <Col sm={6} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Designation </h4>
                    </div>
                    <div className="values text-capitalize">
                      <p>{data?.designation}</p>
                    </div>
                  </div>
                </Col>

                <Col sm={6} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Hourly Payment </h4>
                    </div>
                    <div className="values">
                      <p>
                        {data?.currencyCode === "INR" && "₹"}
                        {data?.currencyCode === "EUR" && "€"}
                        {data?.currencyCode === "USD" && "$"}
                        {data?.hourlyPayment}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Skills </h4>
                    </div>
                    <div className="values">
                      {data?.skillSets?.map((skill, key) => {
                        return (
                          // <p>{`${skill?.domain} : ${skill?.numberOfYears} years ${skill.numberOfMonths} months`}</p>
                          <div className="skill-values justify-content-between">
                            <div className="d-flex">
                              <span className="skillname">{skill?.domain}</span>
                              <span className="expname">
                                {skill?.numberOfYears +
                                  "." +
                                  skill?.numberOfMonths}{" "}
                                years
                              </span>
                            </div>
                            {/* <span className="rating-sc">{skill?.rating}</span> */}
                            <Rating
                              className="rating-area"
                              initialValue={skill?.rating}
                              name="rating"
                              readonly="true"
                              // onClick={(e) => handleOnChange(e, keyIndex, "rating")}
                              // onPointerEnter={onPointerEnter}
                              // onPointerLeave={onPointerLeave}
                              // onPointerMove={onPointerMove}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Col>
                {/* <Col sm={6} className="mb-2 px-1">
              <div className="first-row">
                <div className="labels">
                  <h4>Currency </h4>
                </div>
                <div className="values">
                  <p>{data?.currencyCode}</p>
                </div>
              </div>
            </Col> */}
                <Col sm={12}>
                  <h2 className="mt-4">Booking Details</h2>
                  <hr className="mt-1 mb-3" />
                </Col>
                <Col sm={6} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Booking Requested By </h4>
                    </div>
                    <div className="values text-capitalize">
                      <p>{data?.bookedCompany}</p>
                    </div>
                  </div>
                </Col>
                <Col sm={6} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Booking Requested To </h4>
                    </div>
                    <div className="values text-capitalize">
                      <p>{data?.parentCompany}</p>
                    </div>
                  </div>
                </Col>

                <Col sm={6} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>From </h4>
                    </div>
                    <div className="values">
                      <p>
                        {moment(data?.duration?.fromDate).format(
                          "MMMM DD, YYYY"
                        )}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col sm={6} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>To </h4>
                    </div>
                    <div className="values">
                      <p>
                        {moment(data?.duration?.toDate).format("MMMM DD, YYYY")}
                      </p>
                    </div>
                  </div>
                </Col>
                {/* {data?.bookingExtentionsDetails && (
              <Col sm={6}>
                <div className="first-row">
                  <div className="labels">
                    <h4>Extensions requested </h4>
                  </div>
                  <div className="values">
                    {data?.bookingExtentionsDetails?.length > 0 ? (
                      data?.bookingExtentionsDetails?.map((extention, key) => {
                        return (
                          <p key={key}>{`${key + 1} : ${moment(
                            extention?.duration?.fromDate
                          ).format("DD-MM-YYYY")} - ${moment(
                            extention?.duration?.fromDate
                          ).format("DD-MM-YYYY")}`}</p>
                        );
                      })
                    ) : (
                      <p> No extensions requested</p>
                    )}
                  </div>
                </div>
              </Col>
            )} */}

                <Col sm={6} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Working Days </h4>
                    </div>
                    <div className="values">
                      <p>{data?.workingDays}</p>
                    </div>
                  </div>
                </Col>
                <Col sm={6} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Working Hours Per Day</h4>
                    </div>
                    <div className="values">
                      <p>{data?.workingHoursPerDay}</p>
                    </div>
                  </div>
                </Col>

                <Col
                  sm={data?.sowStatus === "approved" ? 6 : 12}
                  className={"mb-2 px-1"}
                >
                  <div className="first-row">
                    <div className="labels">
                      <h4>Status</h4>
                    </div>
                    <div className="values text-capitalize">
                      <p>{data?.status}</p>
                    </div>
                  </div>
                </Col>

                {data?.sowStatus === "approved" ? (
                  <Col sm={6} className="mb-2 px-1">
                    <div className="first-row">
                      <div className="labels">
                        <h4>SOW</h4>
                      </div>
                      <div className="values text-capitalize">
                        <a
                          href={baseURL + "/" + data?.sowDocument}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          <img src={downloadIcn} width={"21"} alt=""></img>
                        </a>
                      </div>
                    </div>
                  </Col>
                ) : null}

                {/* <Col sm={6} className="mb-2 px-1">
              <div className="first-row">
                <div className="labels">
                  <h4>SOW Status </h4>
                </div>
                <div className="values text-capitalize">
                  <p>
                    {data?.sow_final_status
                      ? data?.sow_final_status
                      : "Pending"}
                    <span>
                      {data?.sowStatus === "approved" && (
                        // eslint-disable-next-line react/jsx-no-target-blank
                        <a
                          href={baseURL + "/" + data?.sowDocument}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          <img src={downloadIcn} width={"21"} alt=""></img>
                        </a>
                      )}
                    </span>
                  </p>
                </div>
              </div>
            </Col> */}
                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Remarks </h4>
                    </div>
                    <div className="values text-capitalize">
                      <p>{data?.remarks}</p>
                    </div>
                  </div>
                </Col>
                <Col sm={12} className="pt-2 px-1">
                  <h2 className="mt-4">Activity</h2>
                  <hr className="mt-1 mb-3" />
                </Col>
                {data?.activity && (
                  <Col sm={12} className="mb-2 px-1">
                    <div className="first-row-2">
                      <div className="labels">
                        <h4>Action </h4>
                      </div>
                      <div className="values">
                        <div className="activity-timeline">
                          {data?.activity?.length > 0 ? (
                            data?.activity?.map((act, key) => {
                              return (
                                <>
                                  <div className="activityboxes">
                                    <p className="action-title">
                                      {act?.action}
                                    </p>
                                    <label key={key}>
                                      {" "}
                                      {act?.action}
                                      {act?.fromDate && act?.toDate
                                        ? " from " +
                                          moment(act.fromDate).format(
                                            "MMMM D, YYYY"
                                          ) +
                                          " to " +
                                          moment(act.toDate).format(
                                            "MMMM D, YYYY"
                                          )
                                        : ""}
                                      {act?.rejectReason
                                        ? ` with reason "${act.rejectReason}"`
                                        : ""}{" "}
                                      on {moment(act?.time).format("LLL")}
                                    </label>
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <p></p>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                )}
              </FormGroup>
            </ModalBody>
          </Modal>
        </>
      ) : (
        <>
          <Modal
            toggle={close}
            scrollable
            isOpen={modal}
            className="bookingmodal rightside-modal"
          >
            <Row>
              <div className="col">
                <ModalHeader className="text-uppercase font-weight-bolder pb-2">
                  <h2>Employee Details</h2>
                </ModalHeader>
              </div>
              <Row>
                <Col>
                  <ModalHeader className="text-uppercase pt-3 pb-2">
                    <Button onClick={onClose} className="mx-1" close />
                  </ModalHeader>
                </Col>
              </Row>
            </Row>
            <ModalBody>
              <FormGroup row className="employee-det-row mb-0 px-2">
                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Employee ID </h4>
                    </div>
                    <div className="values">
                      <p>{data?.empId}</p>
                    </div>
                  </div>
                </Col>

                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Employee Name </h4>
                    </div>
                    <div className="values text-capitalize">
                      <p>{data?.name}</p>
                    </div>
                  </div>
                </Col>

                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Designation </h4>
                    </div>
                    <div className="values text-capitalize">
                      <p>{data?.designation}</p>
                    </div>
                  </div>
                </Col>

                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Contact Number </h4>
                    </div>
                    <div className="values text-capitalize">
                      <p>{data?.mobile ? data?.mobile :'--'}</p>
                    </div>
                  </div>
                </Col>

                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Email ID </h4>
                    </div>
                    <div className="values ">
                      <p>{data?.email}</p>
                    </div>
                  </div>
                </Col>

                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Hourly Payment </h4>
                    </div>
                    <div className="values">
                      <p>
                        {data?.currencyCode === "INR" && "₹"}
                        {data?.currencyCode === "EUR" && "€"}
                        {data?.currencyCode === "USD" && "$"}
                        {data?.hourlyPayment}
                      </p>
                    </div>
                  </div>
                </Col>

                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Skills </h4>
                    </div>
                    <div className="values">
                      {data?.skillSets?.map((skill, key) => {
                        return (
                          // <p>{`${skill?.domain} : ${skill?.numberOfYears} years ${skill.numberOfMonths} months`}</p>
                          <div className="skill-values justify-content-between">
                            <div className="d-flex">
                              <span className="skillname">{skill?.domain}</span>
                              <span className="expname">
                                {skill?.numberOfYears +
                                  "." +
                                  skill?.numberOfMonths}{" "}
                                years
                              </span>
                            </div>
                            {/* <span className="rating-sc">{skill?.rating}</span> */}
                            <Rating
                              className="rating-area"
                              initialValue={skill?.rating}
                              name="rating"
                              readonly="true"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Col>

                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Projects </h4>
                    </div>
                    <div className="values">
                      {data?.projects?.map((project, key) => {
                        return (
                          
                          <div className="skill-values justify-content-between">
                            <div >
                              <span className="skillname">{project?.name}</span>
                              <span className="expname">  <strong>Description:</strong>  {project?.description}</span>
                            </div>
                           
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Col>

                {/* <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Resume </h4>
                    </div>
                    <div className="values text-capitalize">
                      <a href={data?.resume}>{data?.resume}</a>
                    </div>
                  </div>
                </Col> */}
              </FormGroup>
            </ModalBody>
          </Modal>
        </>
      )}
    </div>
  );
};

export default BookingModal;
