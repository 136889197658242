import { combineReducers } from "redux";
import { EmployeeCreationReducer } from "./super-admin/EmployeeCreationReducer";
import authReducer from "./auth/authReducer";
import CompanyApprovalReducer from "./super-admin/CompanyApprovalReducer";
import EmployeeListReducer from "./super-admin/EmployeeListReducer";
import BookingApprovalRequestReducer from "./admin/BookingApprovalRequestReducer";
import ExpiringRequestReducer from "./admin/ExpiringRequestReducer";
import PreClosureRequestReducer from "./admin/PreClosureRequestReducer";
import AllRequestReducer from "./admin/AllRequestReducer";
import AllBookingReducer from "./admin/AllBookingReducer";

import PendingRequestReducer from "./admin/PendingRequestReducer";
import ApprovedRequestReducer from "./admin/ApprovedRequestReducer";
import RejectedRequestReducer from "./admin/RejectedRequestReducer";
import CompletedRequestReducer from "./admin/CompletedRequestReducer";
import submitRequestReducer from "./common/submitRequestReducer";
import EmployeeBookingHistoryListReducer from "./admin/EmployeeBookingHistoryListReducer";
import AvailableBookingEmployeeReducer from "./admin/AvailableBookingEmployeeReducer";
import CompanyApprovedReducer from "./super-admin/CompanyApprovedReducer";
import CompanyRejectedReducer from "./super-admin/CompanyRejectedReducer";
import SowReducer from "./admin/SowReducer";
import BookingExtentionApprovalRequestReducer from "./admin/BookingExtentionApprovalRequestReducer";
import ForgotPasswordReducer from "./ForgotPasswordReducer";
import BookingExtentionApprovedReducer from "./admin/BookingExtentionApprovedReducer";
import BookingExtentionPendingReducer from "./admin/BookingExtentionPendingReducer";
import AllBookingExtentionRequestReducer from "./admin/AllBookingExtentionReducer";
import BookingPendingReducer from "./admin/BookingPendingReducer";
import PendingCountRequestReducer from "./admin/PendingCountRequestReducer";
import PendingSOWOutboundReducer from "./admin/PendingSOWOutboundReducer";
import PendingSOWInboundReducer from "./admin/PendingSOWInboundReducer";
import loginLogoutReducer from "./auth/loginLogoutReducer";
import BookingExtentionRejectedReducer from "./admin/BookingExtentionRejectedReducer";
import ManagerListReducer from "./super-admin/ManagerListReducer";
import PendingPreClosureRequestReducer from "./admin/PendingPreClosureRequestReducer ";
import PendingRecentBookingReducer from "./super-admin/PendingRecentBookingReducer";
import RejectedRecentBookingReducer from "./super-admin/RejectedRecentBookingReducer";
import ApprovedRecentBookingReducer from "./super-admin/ApprovedRecentBookingReducer";
import CompletedRecentBookingReducer from "./super-admin/CompletedRecentBookingReducer";
import NotificationListReducer from "./admin/NotificationListReducer";
import NotificationReducer from "./admin/NotificationReducer";
import socketReducer from "./auth/socketReducer";
import ProfileUpdateReducer from "./common/ProfileUpdateReducer";
const allReducers = combineReducers({
  allEmployees: EmployeeCreationReducer,
  authReducer,
  NotificationReducer,
  AllBookingExtentionRequestReducer,
  CompanyApprovalReducer,
  EmployeeListReducer,
  BookingApprovalRequestReducer,
  PreClosureRequestReducer,
  AllRequestReducer,
  AllBookingReducer,
  PendingRequestReducer,
  PendingCountRequestReducer,
  BookingPendingReducer,
  PendingPreClosureRequestReducer,
  ApprovedRequestReducer,
  RejectedRequestReducer,
  CompletedRequestReducer,
  submitRequestReducer,
  EmployeeBookingHistoryListReducer,
  AvailableBookingEmployeeReducer,
  CompanyApprovedReducer,
  SowReducer,
  PendingSOWOutboundReducer,
  PendingSOWInboundReducer,
  CompanyRejectedReducer,
  ForgotPasswordReducer,
  BookingExtentionApprovalRequestReducer,
  BookingExtentionApprovedReducer,
  BookingExtentionPendingReducer,
  BookingExtentionRejectedReducer,
  ManagerListReducer,
  socketReducer,
  PendingRecentBookingReducer,
  RejectedRecentBookingReducer,
  ApprovedRecentBookingReducer,
  CompletedRecentBookingReducer,
  NotificationListReducer,
  ExpiringRequestReducer,
  loginLogoutReducer,
  ProfileUpdateReducer
});

export default allReducers;
